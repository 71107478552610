import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiBridgeFieldsListResponse, ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchBridgeFields = async (
  bridgeId: number
): Promise<ApiBridgeFieldsListResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getBridgeFields({ bridgeId: bridgeId.toString() }),
    {
      headers: {
        ...getRandomExampleHeader([
          // 'with-automapping',
          'without-automapping',
          // 'with-destination-values',
        ]),
      },
    }
  )
  return apiResponse.data
}

export const useGetBridgeAllFields = (bridgeId: number) =>
  useQuery<ApiBridgeFieldsListResponse, ApiErrorResponse>(
    ['bridgeFields', bridgeId],
    () => fetchBridgeFields(bridgeId),
    {
      // we need a good compromise that allows us to use cached query strategy,
      // but still preventing us to get old data when bridge is edited
      staleTime: 3500,
    }
  )
