import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiSendTestLeadRequestBody,
  ApiSendTestLeadResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from '@app/api/utils/mock'

type PostTestLeadParams = {
  requestBody: ApiSendTestLeadRequestBody
  bridgeId: string | number
}

const postTestLead = async ({
  requestBody,
  bridgeId,
}: PostTestLeadParams): Promise<ApiSendTestLeadResponse> => {
  const apiResponse: AxiosResponse<ApiSendTestLeadResponse> =
    await apiAuthClient.post(
      endpoints.sendTestLead({ bridgeId: `${bridgeId}` }),
      requestBody,
      {
        headers: {
          ...getRandomExampleHeader([
            'success',
            // 'error',
          ]),
        },
      }
    )

  return apiResponse.data
}

export const usePostTestLead = () =>
  useMutation<ApiSendTestLeadResponse, ApiErrorResponse, PostTestLeadParams>(
    postTestLead
  )
