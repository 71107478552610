import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiBridgeResponse,
  ApiErrorResponse,
  ApiSaveBridgeBody,
  ApiSaveBridgeResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from './utils/mock'

const postBridge = async (
  bridgeData: ApiSaveBridgeBody
): Promise<ApiBridgeResponse> => {
  const apiResponse: AxiosResponse<ApiSaveBridgeResponse> =
    await apiAuthClient.post(endpoints.createNewBridge, bridgeData, {
      headers: {
        ...getRandomExampleHeader(['default']),
      },
    })
  return apiResponse.data
}

export const useCreateNewBridge = () =>
  useMutation<ApiSaveBridgeResponse, ApiErrorResponse, ApiSaveBridgeBody>(
    postBridge
  )
