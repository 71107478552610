import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiBridgeResponse,
  ApiErrorResponse,
  ApiUpdateBridgeBody,
  ApiUpdateBridgeResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from './utils/mock'

type PatchBridgeParams = {
  bridgeData: ApiUpdateBridgeBody
  bridgeId: string
}

const patchBridge = async ({
  bridgeData,
  bridgeId,
}: PatchBridgeParams): Promise<ApiBridgeResponse> => {
  const apiResponse: AxiosResponse<ApiUpdateBridgeResponse> =
    await apiAuthClient.patch(
      endpoints.updateBridgeById({ bridgeId }),
      bridgeData,
      {
        headers: {
          ...getRandomExampleHeader([
            // 'with-automapping',
            'with-mapped-field',
            // 'with-destination-values',
          ]),
        },
      }
    )
  return apiResponse.data
}

export const useUpdateBridge = () =>
  useMutation<ApiUpdateBridgeResponse, ApiErrorResponse, PatchBridgeParams>(
    patchBridge
  )
