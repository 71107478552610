import axios from 'axios'
import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'

// Type defintions
interface ApiIntegrationTool {
  id: number
  name: string
  logoUri: string
  xsLogoUri: string
}

interface ApiGetMetaReponseForBbu {
  source?: ApiIntegrationTool
  destination?: ApiIntegrationTool
  meta: {
    headline?: string
  }
}

interface FetchedIntegrationMetaForBbu {
  source: {
    id: number
    name: string
    logoSmallUri: string
    logoUri: string
  }
  destination: {
    id: number
    name: string
    logoSmallUri: string
    logoUri: string
  }
  meta: {
    headline?: string
  }
}

// step1: we create a new fetcher
// As `fetcher` we mean a simple async request that returns some data
const fetchIntegrationsMetaForBridgeByUrl = async (
  source: string,
  destination: string
): Promise<ApiGetMetaReponseForBbu> => {
  // set api url (this might also come from some global configuration)
  const apiUrl = endpoints.integrationsMeta

  // our aysnc request
  const apiResponse = await axios.get(apiUrl, {
    params: {
      source,
      destination,
    },
  })

  // returned data
  return apiResponse.data
}

// step2 (optional but suggested): we create an adapter to "adapt" api raw data to something usable for our UI
// in this way in case api reponse structure change, we can update the change just here
const adaptApiReponse = (
  apiReponse: ApiGetMetaReponseForBbu
): FetchedIntegrationMetaForBbu | null => {
  if (!apiReponse || !apiReponse.source || !apiReponse.destination) {
    return null
  }

  return {
    source: {
      id: apiReponse.source?.id || 0,
      name: apiReponse.source?.name || '-',
      logoSmallUri: apiReponse.source?.xsLogoUri || '',
      logoUri: apiReponse.source?.logoUri || '',
    },
    destination: {
      id: apiReponse.destination?.id || 0,
      name: apiReponse.destination?.name || '',
      logoSmallUri: apiReponse.destination?.xsLogoUri || '',
      logoUri: apiReponse.destination?.logoUri || '',
    },
    meta: {
      headline: apiReponse.meta?.headline,
    },
  }
}

// step3: we finally create our `api request` hook and we export it to be used inside our app
export const useFetchIntegrationsMetaForBridgeByUrl = (
  source?: string,
  destination?: string
) =>
  useQuery(
    // as first param we pass a key to identify the api call
    ['bbuIntegrationsMeta'],
    // as second param we pass our fetcher
    () => fetchIntegrationsMetaForBridgeByUrl(source!, destination!),
    {
      // we don't want auto-updates
      staleTime: Infinity,
      // if we created an adapter in step2, we can pass it to `select` option
      select: adaptApiReponse,
      enabled: Boolean(source && destination),
    }
  )
