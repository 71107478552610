import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { App } from 'Nbee'
import { ApiErrorResponse, ApiGetAppResponse } from 'BackendApi'
import { getRandomExampleHeader } from './utils/mock'

const fetchAppByID = async (appId: string): Promise<App | null> => {
  const apiResponse: AxiosResponse<ApiGetAppResponse> = await apiAuthClient.get(
    endpoints.getAppById({ appId }),
    { headers: { ...getRandomExampleHeader(['example-1']) } }
  )
  const app = apiResponse.data.data

  // since this api is from existing lambda function (not coming from new laravel backed)
  // we normalize response the to adapt it as new nbee App model
  return app
    ? {
        id: parseInt(appId, 10),
        parentAppId: app.parentAppId,
        logoUri: app.logoUri,
        logoUriSmall: app.xsLogoUri,
        name: app.name,
        video: app.video,
        docUrl: app.docUrl,
        authType: app.authType,
      }
    : null
}

export const useGetAppById = (appId: string | number) =>
  useQuery<App | null, ApiErrorResponse>(['app', appId], () =>
    fetchAppByID(`${appId}`)
  )
