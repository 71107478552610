import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiGetAppsResponse } from 'BackendApi'
import { adaptApiAppReponse } from '@app/api/utils/apps'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchMyApps = async (): Promise<ApiGetAppsResponse> => {
  const apiResponse = await apiAuthClient.get(endpoints.getConnectedApps, {
    headers: {
      ...getRandomExampleHeader(['example-1']),
    },
  })
  return apiResponse.data
}

export const useFetchConnectedApps = () =>
  useQuery(['connectedApps'], () => fetchMyApps(), {
    staleTime: 0,
    select: adaptApiAppReponse,
  })
