import { useQuery } from 'react-query'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiUserVerifiedEmails,
  ApiUserVerifiedEmailsResponse,
} from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const getVerifiedEmails = async (
  isEmailValidation?: boolean
): Promise<ApiUserVerifiedEmails | undefined> => {
  const apiResponse = await apiAuthClient.get<ApiUserVerifiedEmailsResponse>(
    endpoints.getVerifiedEmails,
    {
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    }
  )

  return apiResponse.data.data
}

export const useGetVerifiedEmails = (isEmailValidation?: boolean) =>
  useQuery<ApiUserVerifiedEmails | undefined, ApiErrorResponse>(
    ['verifiedEmails', isEmailValidation],
    () => getVerifiedEmails(isEmailValidation),
    { enabled: isEmailValidation }
  )
