import {
  ApiPostBridgePerformanceBoosterResponse,
  ApiErrorResponse,
} from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import { useMutation } from 'react-query'

type PostPerformanceBoosterByBridgeIdParams = {
  bridgeId: string
}

const postPerformanceBoosterByBridgeId = async ({
  bridgeId,
}: PostPerformanceBoosterByBridgeIdParams): Promise<ApiPostBridgePerformanceBoosterResponse> => {
  const apiResponse = await apiAuthClient.post(
    endpoints.postBridgePerformanceBooster({ bridgeId }),
    undefined,
    {
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    }
  )
  return apiResponse.data
}

export const usePostPerformanceBoosterByBridgeId = () =>
  useMutation<
    ApiPostBridgePerformanceBoosterResponse,
    ApiErrorResponse,
    PostPerformanceBoosterByBridgeIdParams
  >(postPerformanceBoosterByBridgeId)
