import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiTestableFieldsResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

const fetchTestFieldsByBridgeId = async (
  bridgeId: number | string
): Promise<ApiTestableFieldsResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getTestableBridgeFields({ bridgeId: `${bridgeId}` })
  )
  return apiResponse.data
}

export const useGetBridgeTestableFields = (bridgeId: number | string) =>
  useQuery<ApiTestableFieldsResponse, ApiErrorResponse>(
    ['testableFields', bridgeId],
    () => fetchTestFieldsByBridgeId(bridgeId!),
    {
      staleTime: 0,
    }
  )
