import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiGetIntegrationResponse,
  ApiErrorResponse,
  ApiSaveIntegrationRequestBody,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { Integration } from 'Nbee'
import { getRandomExampleHeader } from './utils/mock'

type PatchIntegrationParams = {
  requestBody: ApiSaveIntegrationRequestBody
  integrationId: string
}

type MaybeIntegration = Integration | undefined

const patchIntegration = async ({
  requestBody,
  integrationId,
}: PatchIntegrationParams): Promise<MaybeIntegration> => {
  const apiResponse: AxiosResponse<ApiGetIntegrationResponse> =
    await apiAuthClient.patch(
      endpoints.updateIntegrationById({ integrationId }),
      requestBody,
      {
        headers: {
          ...getRandomExampleHeader(['example-1']),
        },
      }
    )
  return apiResponse.data.data?.integration
}

export const useUpdateIntegration = () => {
  const queryClient = useQueryClient()

  return useMutation<
    MaybeIntegration,
    ApiErrorResponse,
    PatchIntegrationParams
  >(patchIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['integration'])
    },
  })
}
