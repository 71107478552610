import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiFiltersSchemaResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const getFiltersSchema = async (): Promise<ApiFiltersSchemaResponse> => {
  const response = await apiAuthClient.get(endpoints.getFiltersSchema, {
    headers: {
      ...getRandomExampleHeader(['default']),
    },
  })

  return response.data
}

export const useGetFiltersSchema = () =>
  useQuery<ApiFiltersSchemaResponse, ApiErrorResponse>(
    ['filters-schema'],
    getFiltersSchema
  )
