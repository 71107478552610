import { useQuery } from 'react-query'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import {
  ApiBridgeAvailablePermissionCheckResponse,
  ApiErrorResponse,
} from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchBridgePermissionCheckInfo = async (
  bridgeId: string
): Promise<ApiBridgeAvailablePermissionCheckResponse> => {
  const apiResponse =
    await apiAuthClient.get<ApiBridgeAvailablePermissionCheckResponse>(
      endpoints.getRunBridgePermissionCheck({ bridgeId }),
      {
        headers: {
          ...getRandomExampleHeader([
            // 'with-previous-recent-errors', // I need to execute the check again
            // 'with-previous-old-errors', // I need to execute the check again
            'with-previous-old-success', // I need to execute the check again
            // 'without-previous-checks', // I need to execute the check for the first time
            // 'with-previous-recent-success', // I don't need to execute the check again
            // 'without-available-checks', // I can't execute any check
          ]),
        },
      }
    )
  return { ...apiResponse.data } // add boolean flag to payload
}

export const useGetBridgeAvailablePermissionChecks = (bridgeId?: number) =>
  useQuery<ApiBridgeAvailablePermissionCheckResponse, ApiErrorResponse>(
    ['permission-checks'],
    () => {
      if (!bridgeId) {
        throw new Error('bridgeId is required for this query')
      }
      return fetchBridgePermissionCheckInfo(bridgeId.toString())
    },
    {
      staleTime: Infinity,
      enabled: !!bridgeId,
    }
  )
