import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiSingleFormulaSchemaResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const getSingleFormulaSchema = async (
  bridgeId: string,
  formulaId: string
): Promise<ApiSingleFormulaSchemaResponse> => {
  const response = await apiAuthClient.get(
    endpoints.getSingleFormulaSchema({ bridgeId, formulaId }),
    {
      headers: {
        ...getRandomExampleHeader(['F1']),
      },
    }
  )

  return response.data
}

export const useGetSingleFormulaSchema = (
  bridgeId?: string,
  formulaId?: string
) =>
  useQuery<ApiSingleFormulaSchemaResponse, ApiErrorResponse>(
    ['single-formula-schema', formulaId],
    () => getSingleFormulaSchema(bridgeId!, formulaId!),
    {
      staleTime: Infinity,
      enabled: !!bridgeId && !!formulaId,
    }
  )
