import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiAddDropdownOptionRequestBody,
  ApiAddDropdownOptionResponse,
  ApiErrorResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'

const postAddDropdownOption = async (
  postBody: ApiAddDropdownOptionRequestBody
): Promise<ApiAddDropdownOptionResponse> => {
  const apiResponse: AxiosResponse<ApiAddDropdownOptionResponse> =
    await apiAuthClient.post(endpoints.addDropdownOption, postBody)

  return apiResponse.data
}

export const useAddDropdownOption = () =>
  useMutation<
    ApiAddDropdownOptionResponse,
    ApiErrorResponse,
    ApiAddDropdownOptionRequestBody
  >(postAddDropdownOption)
