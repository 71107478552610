import {
  ApiBridgeResponse,
  ApiMappedField,
  ApiSaveBridgeBody,
} from 'BackendApi'
import { BridgeFormValues } from 'Nbee'
import { isFieldMapped } from '@features/nbee/FieldsMappingForm/utils'

const emptyBridge: BridgeFormValues = {
  name: '',
  source: {},
  destination: {},
  settings: {
    emailReceipt: {
      active: false,
      recipients: [],
      subject: '',
    },
  },
  sourceFilters: {
    conditions: [],
  },
}

export const transformBridgeApiToBridgeFormValues = (
  apiReponse: ApiBridgeResponse,
  includeFieldMapping?: boolean
): BridgeFormValues => {
  if (!apiReponse || !apiReponse.data || !apiReponse.data.bridge) {
    return emptyBridge
  }

  const bridgeConfiguration: BridgeFormValues = {
    name: apiReponse.data.bridge.name,
    source: {
      appId: apiReponse.data.bridge.source?.appId,
      integrationId: apiReponse.data.bridge.source?.integrationId,
      settings: apiReponse.data.bridge.source?.settings || [],
    },
    destination: {
      appId: apiReponse.data.bridge.destination?.appId,
      integrationId: apiReponse.data.bridge.destination?.integrationId,
      settings: apiReponse.data.bridge.destination?.settings || [],
    },
    ui: {
      bridgeId: apiReponse.data.bridge.id,
      isBridgeEnabled:
        apiReponse.data.bridge.manualStatus === 3 ||
        apiReponse.data.bridge.manualStatus === 'enabled',
      testEnabled: apiReponse.data.bridge.ui?.testEnabled,
      emailReceipt: apiReponse.data.bridge.ui?.emailReceipt,
    },
    settings: {
      emailReceipt: {
        active: Boolean(apiReponse.data.bridge.settings?.emailReceipt.active),
        recipients:
          apiReponse.data.bridge.settings?.emailReceipt.recipients || [],
        subject: apiReponse.data.bridge.settings?.emailReceipt.subject || '',
      },
    },
    sourceFilters: {
      conditions: apiReponse.data.bridge.sourceFilters?.conditions || [],
    },
    manualStatus: apiReponse.data.bridge.manualStatus,
    templateBasedFieldsMapping:
      apiReponse.data.bridge.templateBasedFieldsMapping,
  }

  const bridgeFieldMappings: {
    fieldsMapping: BridgeFormValues['fieldsMapping']
  } = {
    fieldsMapping: (apiReponse.data.bridge.fieldsMapping || []).map(
      (apiField, fieldIndex) => {
        return {
          destinationFieldId: apiField.destinationFieldId,
          mappingType: apiField.mappingType,
          mapping: apiField.mapping.map((mappingItem, mappingIndex) => {
            // generate unique ids for items of the mapping array
            // by concatenating the index of the field
            // with the sourceFieldId or formula id or text
            // and the index of the mapping item
            const name =
              mappingItem.sourceFieldId ||
              mappingItem?.formula?.id ||
              mappingItem.text ||
              ''
            const mappingId = `${fieldIndex}-${name}-${mappingIndex}`
            return {
              index: mappingIndex,
              sourceFieldId: mappingItem.sourceFieldId,
              fieldType: mappingItem.fieldType,
              text: mappingItem.text,
              formula: mappingItem.formula
                ? {
                    id: mappingItem?.formula?.id,
                    params: [...mappingItem?.formula?.params],
                  }
                : null,
              id: `${mappingId}`,
            }
          }),
        }
      }
    ),
  }

  return includeFieldMapping
    ? {
        ...bridgeConfiguration,
        ...bridgeFieldMappings,
      }
    : {
        ...bridgeConfiguration,
      }
}

export const transformBridgeFormValuesToApiSaveBridgeBody = (
  formValues: BridgeFormValues,
  options?: {
    step?: 1 | 2
  }
): ApiSaveBridgeBody => {
  const fieldsMapping: ApiMappedField[] = (formValues.fieldsMapping || [])
    .filter(isFieldMapped)
    .map((field) => ({
      destinationFieldId: field.destinationFieldId || '',
      mappingType: field.mappingType,
      mapping: field.mapping.map((m) => {
        return {
          // remove parameters from the mapping object that are not needed for the API
          sourceFieldId: m.sourceFieldId,
          fieldType: m.fieldType,
          text: m.text,
          formula: m.formula,
        }
      }),
    }))

  return {
    name: formValues.name,
    source: formValues.source,
    destination: formValues.destination,
    fieldsMapping,
    settings: {
      emailReceipt: {
        // if canShow is undefined or true, we want to only look at the active status, otherwise set the active status to false
        active:
          Boolean(formValues.settings?.emailReceipt?.active) &&
          (formValues.settings?.emailReceipt?.canShow ?? true),
        recipients: formValues.settings?.emailReceipt?.recipients,
        subject: formValues.settings?.emailReceipt?.subject,
      },
    },
    sourceFilters: formValues?.sourceFilters,
    templateBasedFieldsMapping: formValues.templateBasedFieldsMapping,
    ui: options?.step
      ? {
          step: options?.step,
        }
      : undefined,
  }
}
