import { getRandomExampleHeader } from '@app/api/utils/mock'
import {
  ApiBridgePerformanceBoosterResponse,
  ApiErrorResponse,
} from 'BackendApi'
import { endpoints } from '@app/api/config'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { useQuery } from 'react-query'

const fetchPerformanceBoosterByBridgeId = async (
  bridgeId: string
): Promise<ApiBridgePerformanceBoosterResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getBridgePerformanceBooster({ bridgeId }),
    {
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    }
  )
  return apiResponse.data
}

export const useGetPerformanceBoosterByBridgeId = (
  performanceBoosterAvailable?: boolean,
  bridgeId?: string
) =>
  useQuery<ApiBridgePerformanceBoosterResponse, ApiErrorResponse>(
    ['performanceBooster', bridgeId],
    () => fetchPerformanceBoosterByBridgeId(bridgeId!),
    {
      enabled: !!performanceBoosterAvailable,
      staleTime: 0,
    }
  )
