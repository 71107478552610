import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiTestFieldsMappingRequestBody,
  ApiTestFieldsMappingResponse,
  ApiTestError,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from '@app/api/utils/mock'

type testFieldsParams = {
  fieldsToTest: ApiTestFieldsMappingRequestBody
  bridgeId: string | number
}

const testFieldsMapping = async ({
  fieldsToTest,
  bridgeId,
}: testFieldsParams): Promise<ApiTestFieldsMappingResponse> => {
  const apiResponse: AxiosResponse<ApiTestFieldsMappingResponse> =
    await apiAuthClient.post(
      endpoints.testFieldsMapping({ bridgeId: bridgeId }),
      fieldsToTest,
      {
        headers: {
          // Prefer: 'code= 200',
          ...getRandomExampleHeader([
            // 'with-errors',
            // 'With errors',
            'when-no-errors',
          ]),
        },
      }
    )
  const testResponse = apiResponse.data?.data?.errors
  return {
    data: {
      errors: testResponse,
    },
  }
}

export const useTestFieldsMapping = () =>
  useMutation<ApiTestFieldsMappingResponse, ApiTestError, testFieldsParams>(
    testFieldsMapping
  )
