import axios from 'axios'
import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'

// Type defintions
interface ApiFastAppSurveyIndustriesResponse {
  Industries?: string[]
  ClientTiers?: string[]
  Pipelines?: string[]
}

type FetchedIndustries = { value: string; label: string }[]

// utils
const moveToLast = (arr: string[], valueToMoveAsLast: string) => [
  ...arr.filter((val) => val !== valueToMoveAsLast),
  valueToMoveAsLast,
]
const removeUnwantedValues = (arr: string[], valuesToRemove: string[]) =>
  arr.filter((val) => !valuesToRemove.includes(val))

//
// step1: we create a new fetcher
const fetchFastAppSurveyIndustries =
  async (): Promise<ApiFastAppSurveyIndustriesResponse> => {
    const apiResponse = await axios.get(endpoints.fastAppSurveyData)
    return apiResponse.data
  }

//
// step2 (optional but suggested): we create an adapter to "adapt" api raw data to something usable for our UI
const adaptApiReponse = (
  apiReponse: ApiFastAppSurveyIndustriesResponse
): FetchedIndustries => {
  if (!apiReponse || !apiReponse.Industries) {
    return []
  }

  // https://leadsbridge.atlassian.net/browse/SB-221
  const industriesSorted = moveToLast(apiReponse.Industries.sort(), 'Others')
  const industriesCleaned = removeUnwantedValues(industriesSorted, [
    'Unclassified',
  ])
  return industriesCleaned.map((industry) => ({
    label: industry,
    value: industry,
  }))
}

//
// step3: we finally create our `api request` hook and we export it to be used inside our app
export const useFastAppSurveyIndustries = () =>
  useQuery(['fastAppSurveyIndustries'], () => fetchFastAppSurveyIndustries(), {
    select: adaptApiReponse,
    staleTime: Infinity,
  })
