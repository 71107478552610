import { useQuery } from 'react-query'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiUser, ApiUserResponse } from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchUserInfo = async (): Promise<ApiUser | undefined> => {
  const apiResponse = await apiAuthClient.get<ApiUserResponse>(
    endpoints.getUser,
    {
      headers: {
        ...getRandomExampleHeader([
          'example-1',
          // 'no-credit-card',
          // 'plan-freemium-no-card',
          // 'new-user-freemium',
          // 'user-pro-6-5000',
          // 'impersonification',
          // 'user-downgraded',
        ]),
      },
    }
  )
  return apiResponse.data.data
}

export const useGetUserInfo = (shouldFetchData?: boolean) =>
  useQuery<ApiUser | undefined, ApiErrorResponse>(['userInfo'], fetchUserInfo, {
    enabled: !!shouldFetchData,
  })
