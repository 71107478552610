import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { ApiUpdateIntegrationListenerBody } from '@app/@typings/Api/integration'

type PatchIntegrationListenerParams = {
  requestBody: ApiUpdateIntegrationListenerBody
  integrationId: string
}

const patchIntegrationListener = async ({
  requestBody,
  integrationId,
}: PatchIntegrationListenerParams) => {
  const apiResponse = await apiAuthClient.patch(
    endpoints.updateIntegrationListener({ integrationId }),
    requestBody
  )
  return apiResponse.data
}

export const useUpdateIntegrationListener = () => {
  // const queryClient = useQueryClient() // <- get the query client

  return useMutation<unknown, ApiErrorResponse, PatchIntegrationListenerParams>(
    patchIntegrationListener
    /* ,{
      onSuccess: () => {
        queryClient.invalidateQueries('integration-listener') // <- invalidate the query on mutation success
      },
    } */
  )
}
