import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiSaveBridgeFilterRequestBody,
  ApiSaveBridgeFilterResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'

type SaveBridgeFilterRulesParams = {
  bridgeId: string | number
  requestBody: ApiSaveBridgeFilterRequestBody
}

const postSaveBridgeFilterRules = async ({
  bridgeId,
  requestBody,
}: SaveBridgeFilterRulesParams): Promise<ApiSaveBridgeFilterResponse> => {
  const apiResponse: AxiosResponse<ApiSaveBridgeFilterResponse> =
    await apiAuthClient.post(
      endpoints.createUpdateBridgeFilterRules({ bridgeId: `${bridgeId}` }),
      requestBody,
      {
        // headers: {
        //   ...getRandomExampleHeader([
        //     'success',
        //   ]),
        // },
      }
    )

  return apiResponse.data
}

export const usePostSaveBridgeFilterRules = () =>
  useMutation<
    ApiSaveBridgeFilterResponse,
    ApiErrorResponse,
    SaveBridgeFilterRulesParams
  >(postSaveBridgeFilterRules)
