import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiGetIntegrationResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { Integration } from 'Nbee'

export type IntegrationByLegacyIdParams = {
  appId: number | string
  instanceId: number | string
  instanceType: number | string
}

const fetchIntegrationByLegacyId = async (
  params: IntegrationByLegacyIdParams
): Promise<ApiGetIntegrationResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getIntegrationByLegacyId,
    {
      params: params,
    }
  )
  return apiResponse.data
}

const adaptApiReponse = (
  apiReponse: ApiGetIntegrationResponse
): Integration | undefined => {
  return apiReponse?.data?.integration
}

export const useGetIntegrationByLegacyId = (
  params?: IntegrationByLegacyIdParams
) =>
  useQuery<
    ApiGetIntegrationResponse,
    ApiErrorResponse,
    Integration | undefined
  >(
    ['integration', params?.appId, params?.instanceId],
    () => fetchIntegrationByLegacyId(params!),
    {
      staleTime: Infinity,
      enabled: !!params,
      select: adaptApiReponse,
    }
  )
