import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

type PostSendCodeParams = {
  email: string
}

const postSendCode = async ({ email }: PostSendCodeParams) => {
  const apiResponse = await apiAuthClient.post(endpoints.postSendCode, {
    email,
    // link: true, // This parameter allows to send a link via email (and not a code) for the Legacy experience
  })
  return apiResponse.data
}

export const useSendCode = () =>
  useMutation<unknown, ApiErrorResponse, PostSendCodeParams>(postSendCode)
