import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiDeleteBridgeFilterResponse, ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'

type DeleteBridgeFilterRulesParams = {
  bridgeId: string | number
}

const deleteBridgeFilterRules = async ({
  bridgeId,
}: DeleteBridgeFilterRulesParams): Promise<ApiDeleteBridgeFilterResponse> => {
  const apiResponse: AxiosResponse<ApiDeleteBridgeFilterResponse> =
    await apiAuthClient.delete(
      endpoints.deleteBridgeFilterRules({ bridgeId: `${bridgeId}` }),
      {
        // headers: {
        //   ...getRandomExampleHeader([
        //     'success',
        //   ]),
        // },
      }
    )

  return apiResponse.data
}

export const useDeleteBridgeFilterRules = () =>
  useMutation<
    ApiDeleteBridgeFilterResponse,
    ApiErrorResponse,
    DeleteBridgeFilterRulesParams
  >(deleteBridgeFilterRules)
