import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiRunBridgePermissionCheckResponse,
  ApiBridgeAvailablePermissionChecklist,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from './utils/mock'

export type runBridgePermissionChecksParams = {
  bridgeId: string | number
  permissionChecklist: ApiBridgeAvailablePermissionChecklist[]
  role?: string
}

const runBridgePermissionChecks = async ({
  bridgeId,
  permissionChecklist,
  role,
}: runBridgePermissionChecksParams): Promise<ApiRunBridgePermissionCheckResponse | null> => {
  // Sort the permission checklist: first by role, then by order within each role
  const sortedPermissionChecklist = permissionChecklist
    .sort((a, b) => {
      if (a.role === b.role) {
        // If roles are the same, sort by order
        return a.order - b.order
      }
      return a.role > b.role ? 1 : -1 // Otherwise, sort alphabetically by role
    })
    .filter((item) => (role ? item.role === role : true))

  let lastResponse = null

  // We are going through all our sorted checks
  for (const permissionCheck of sortedPermissionChecklist) {
    // For each check we create a request body
    const requestBody = {
      checkId: permissionCheck.checkId,
      role: permissionCheck.role,
    }
    // Then we make a request to the server
    const apiResponse: AxiosResponse<ApiRunBridgePermissionCheckResponse> =
      await apiAuthClient.post(
        endpoints.getRunBridgePermissionCheck({ bridgeId: `${bridgeId}` }),
        requestBody,
        {
          headers: {
            ...getRandomExampleHeader([
              // 'success',
              'error',
            ]),
          },
        }
      )

    lastResponse = apiResponse.data

    // If the server response is unsuccessful or result is false, generate an error payload and reject the promise
    if (
      !lastResponse.data.response ||
      lastResponse.data.response.result === false
    ) {
      // simulate generic error payload
      const error = {
        response: {
          data: {
            error: {
              code: lastResponse.data.response?.errorCode,
              message: lastResponse.data.response?.errorMessage,
            },
          },
          status: 200, // or any suitable status code
        },
      }

      throw error
    }
  }
  // Return the last successful server response
  return lastResponse
}

export const useRunBridgePermissionChecks = () =>
  useMutation<
    ApiRunBridgePermissionCheckResponse | null,
    ApiErrorResponse,
    runBridgePermissionChecksParams
  >(runBridgePermissionChecks)
