import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  TestIntegrationCredentialsResponse,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'

const fetchTestIntegrationCredentials = async (
  integrationId: number,
  appId?: number
): Promise<TestIntegrationCredentialsResponse | undefined> => {
  if (!integrationId) {
    return undefined
  }
  const apiResponse = await apiAuthClient.get(
    endpoints.testIntegrationCredentialsById({
      integrationId: `${integrationId}`,
    }),
    {
      params: {
        appId,
      },
    }
  )
  return apiResponse.data
}

export const useTestIntegrationCredentialsById = (
  integrationId?: number,
  appId?: number
) =>
  useQuery<TestIntegrationCredentialsResponse | undefined, ApiErrorResponse>(
    ['testIntegration', integrationId, appId],
    () => fetchTestIntegrationCredentials(integrationId!, appId),
    {
      enabled: Boolean(integrationId),
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  )
