import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiBridgeResponse, ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { BridgeRedirectUrl } from 'Nbee'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchRedirectUriBySourceAndDestination = async (
  sourceId: number,
  destinationId: number
): Promise<BridgeRedirectUrl> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getRedirectUriBySourceAndDestination,
    {
      params: { sourceId, destinationId },
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    }
  )
  return apiResponse.data.data
}

export const useGetRedirectUriBySourceAndDestination = (
  sourceId?: number,
  destinationId?: number
) =>
  useQuery<BridgeRedirectUrl, ApiErrorResponse>(
    ['step3RedirectUri'], // as first param we pass a key to identify the api call
    () => fetchRedirectUriBySourceAndDestination(sourceId!, destinationId!), // as second param we pass our fetcher
    {
      enabled: Boolean(sourceId && destinationId),
    }
  )
