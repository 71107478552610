import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiUser,
  SignupBodyRequest,
  SignupResponse,
} from 'BackendApi'
import { AxiosResponse } from 'axios'
import { apiPublicClient } from '@app/services/apiPublicClient'

type PostSignupParams = {
  requestBody: SignupBodyRequest
}

const postSignup = async ({
  requestBody,
}: PostSignupParams): Promise<ApiUser | undefined> => {
  const apiResponse = await apiPublicClient.post<SignupResponse>(
    endpoints.signup,
    requestBody
  )

  return apiResponse.data.data
}

export const useSignupWithEmail = () =>
  useMutation<ApiUser | undefined, ApiErrorResponse, PostSignupParams>(
    postSignup
  )
