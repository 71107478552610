import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiAppsCompatibility,
  ApiAppsCompatibilityResponse,
  ApiErrorResponse,
} from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'
import { apiAuthClient } from '@app/services/apiAuthClient'

const fetchCheckAppsCompatibility = async (
  sourceId?: number,
  destinationId?: number
): Promise<ApiAppsCompatibility> => {
  const apiResponse = await apiAuthClient.get<ApiAppsCompatibilityResponse>(
    endpoints.checkAppsCompatibility,
    {
      params: {
        sourceId,
        destinationId,
      },
      headers: {
        ...getRandomExampleHeader([
          'example-1',
          // 'Email receipt enabled',
          // 'Email receipt disabled',
          // 'Can proceed',
          // 'Upgrade source',
          // 'Upgrade destination',
          // 'Upgrade both',
          // 'Only obe',
          // 'No compatibility',
          // 'Switch',
        ]),
      },
    }
  )
  return apiResponse.data.data
}

export const useCheckAppsCompatibility = (
  sourceId?: number,
  destinationId?: number
) =>
  useQuery<ApiAppsCompatibility, ApiErrorResponse>(
    [`checkCompatibility`, sourceId, destinationId],
    () => fetchCheckAppsCompatibility(sourceId, destinationId),
    {
      // run query only when both sourceId and destinationId are available
      enabled: !!sourceId && !!destinationId,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
