import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiPublishBridgeResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from '@app/api/utils/mock'

type PublishBridgeParams = {
  bridgeId: string | number
}

const postPublishBridge = async ({
  bridgeId,
}: PublishBridgeParams): Promise<ApiPublishBridgeResponse> => {
  const apiResponse: AxiosResponse<ApiPublishBridgeResponse> =
    await apiAuthClient.post(
      endpoints.publishBridgeById({ bridgeId: `${bridgeId}` }),
      undefined,
      {
        headers: {
          ...getRandomExampleHeader([
            'first-bridge-published',
            // 'Bridge published',
            // 'Bridges limit reached',
          ]),
        },
      }
    )
  return apiResponse.data
}

export const usePublishBridge = () =>
  useMutation<ApiPublishBridgeResponse, ApiErrorResponse, PublishBridgeParams>(
    postPublishBridge
  )
