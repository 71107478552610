import { endpoints } from '@app/api/config'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { useMutation } from 'react-query'
import {
  ApiErrorResponse,
  ApiPostRedirectUriResponse,
  ApiSettingValue,
} from 'BackendApi'
import { IntegrationFormField } from 'Nbee'

type PostParamsRedirectUri = {
  appId: number
  credentials?: IntegrationFormField[]
}

const postGenIntegrationRedirectUri = async ({
  appId,
  credentials,
}: PostParamsRedirectUri): Promise<ApiPostRedirectUriResponse> => {
  const apiResponse = await apiAuthClient.post(endpoints.postGenRedirectUri, {
    appId,
    credentials,
  })

  return apiResponse.data
}

export const useGenIntegrationRedirectUri = () =>
  useMutation<
    ApiPostRedirectUriResponse,
    ApiErrorResponse,
    PostParamsRedirectUri
  >(postGenIntegrationRedirectUri)
