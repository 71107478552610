import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiFormulasSchemaResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const getFormulasSchema = async (
  bridgeId: string
): Promise<ApiFormulasSchemaResponse> => {
  const response = await apiAuthClient.get(
    endpoints.getFormulasSchema({ bridgeId }),
    {
      headers: {
        ...getRandomExampleHeader(['default']),
      },
    }
  )

  return response.data
}

export const useGetFormulasSchema = (bridgeId?: string) =>
  useQuery<ApiFormulasSchemaResponse, ApiErrorResponse>(
    ['formulas-schema'],
    () => getFormulasSchema(bridgeId!),
    {
      staleTime: Infinity,
      enabled: !!bridgeId,
    }
  )
