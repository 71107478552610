import { endpoints } from '@app/api/config'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { useMutation } from 'react-query'
import {
  ApiErrorResponse,
  ApiGetIntegrationResponse,
  ApiSettingValue,
} from 'BackendApi'

type PostGrantCodeParams = {
  payload: Record<string, any>[]
  integrationId?: number
  appId: number
}

const postAuthFromGrantCode = async ({
  appId,
  integrationId,
  payload,
}: PostGrantCodeParams): Promise<ApiGetIntegrationResponse> => {
  const apiResponse = await apiAuthClient.post(endpoints.postAuthGrantCode, {
    appId,
    integrationId,
    payload,
  })

  return apiResponse.data
}

export const useAuthFromGrantCode = () =>
  useMutation<ApiGetIntegrationResponse, ApiErrorResponse, PostGrantCodeParams>(
    postAuthFromGrantCode
  )
