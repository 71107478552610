import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiDisableBridgeResponse, ApiErrorResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'

type DisableBridgeParams = {
  bridgeId: number
}

const postDraftBridge = async ({
  bridgeId,
}: DisableBridgeParams): Promise<ApiDisableBridgeResponse> => {
  const apiResponse: AxiosResponse<ApiDisableBridgeResponse> =
    await apiAuthClient.post(
      endpoints.disableBridgeById({ bridgeId: `${bridgeId}` })
    )
  return apiResponse.data
}

export const useDisableBridgeById = () =>
  useMutation<ApiDisableBridgeResponse, ApiErrorResponse, DisableBridgeParams>(
    postDraftBridge
  )
