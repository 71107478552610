export const getRandomExampleHeader = (exampleNames: string[]) => {
  if (!process.env.REACT_USE_MOCK_API) {
    return {}
  }

  const totalExamples = exampleNames.length
  const exampleName = exampleNames[Math.floor(Math.random() * totalExamples)]

  return {
    Prefer: `example=${exampleName}`,
  }
}
