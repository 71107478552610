import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiSetupBridgeResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'

type PatchBridgeParams = {
  bridgeId: number
}

const postSetupBridge = async ({
  bridgeId,
}: PatchBridgeParams): Promise<ApiSetupBridgeResponse> => {
  const apiResponse: AxiosResponse<ApiSetupBridgeResponse> =
    await apiAuthClient.post(
      endpoints.setupBridgeById({ bridgeId: `${bridgeId}` })
    )
  return apiResponse.data
}

export const useSetupBridgeById = () =>
  useMutation<ApiSetupBridgeResponse, ApiErrorResponse, PatchBridgeParams>(
    postSetupBridge
  )
